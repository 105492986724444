import React, { useEffect, useState } from "react";
import "./private.pem";
import "./SASS/main.scss";
import Loader from "./components/Loader";
import { JaaSMeeting } from "@jitsi/react-sdk";
// import { inRange } from "lodash";
// var jsonwebtoken = require("jsonwebtoken");
// var uuid = require("uuid-random");

const App = () => {
  const [displayName, setDisplayName] = useState("");
  const [roomName, setRoomName] = useState("");

  const [onCall, setOnCall] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    if (roomName && displayName) setOnCall(true);
  };

  useEffect(() => {
    invalidUrl();
    // urlEntered();
  });

  function invalidUrl() {
    const url = window.location.href.split("/");
    if (url[4]) {
      console.log("Invalid Url");
      window.location.href = "/";
    } else {
      urlEntered();
    }
  }
  function urlEntered() {
    // const roomName = window.location.href.split("/").pop();
    const roomName = window.location.href.split("/")[3];

    // console.log(roomName);
    if (roomName !== "") {
      setRoomName(roomName);
      // setDisplayName("Tony");
      setOnCall(true);
    }
  }

  function callFinished() {
    window.location.href = "/";
    console.log("Jitsi Meet is ready to be closed");
  }
  return (
    <div className="container-fluid main-container">
      <div className="row">
        <div className="col">
          <div className="jitsi-container">
            {onCall ? (
              <JaaSMeeting
                appId={"vpaas-magic-cookie-d6c9c4c0a5584571a7afbab4dad33f60"}
                roomName={roomName}
                displayName={displayName}
                spinner={Loader}
                onReadyToClose={() => callFinished(true)}
                onApiReady={(externalApi) =>
                  console.log("Jitsi Meet External API", externalApi)
                }
                interfaceConfigOverwrite={{
                  MOBILE_APP_PROMO: false
                  // VIDEO_LAYOUT_FIT: "nocrop"
                }}
                configOverwrite={{
                  disableThirdPartyRequests: true,
                  prejoinPageEnabled: false,
                  disableLocalVideoFlip: true,
                  startWithAudioMuted: false,
                  startWithVideoMuted: false,
                  channelLastN: -1,
                  startLastN: 1,
                  desktopSharingFrameRate: {
                    min: 30,
                    max: 144
                  },
                  enableLobbyChat: true,
                  speakerStats: {
                    disabled: false
                  },
                  maxFullResolutionParticipants: -1,
                  videoQuality: {
                    preferredCodec: "H264",
                    enforcePreferredCodec: true
                  },
                  breakoutRooms: {
                    hideAddRoomButton: false,
                    hideJoinRoomButton: false,
                    hideAutoAssignButton: false
                  },
                  faceLandmarks: {
                    enableFaceCentering: true,
                    enableFaceExpressionsDetection: true,
                    enableDisplayFaceExpressions: true,
                    enableRTCStats: false,
                    faceCenteringThreshold: 20,
                    captureInterval: 1000
                  },
                  audioQuality: {
                    stereo: true,
                    opusMaxAverageBitrate: 48000
                  },
                  resolution: 1080,
                  enableNoisyMicDetection: true,
                  enableNoAudioDetection: true,
                  p2p: {
                    enabled: true,
                    preferredCodec: "H264",
                    stunServers: [
                      { urls: "stun:meet-jit-si-turnrelay.jitsi.net:443" }
                    ]
                  },
                  whiteboard: {
                    enabled: true,
                    collabServerBaseUrl:
                      "https://excalidraw-backend-pilot.jitsi.net"
                  },
                  conferenceInfo: {
                    alwaysVisible: [
                      "recording",
                      "raised-hands-count"
                      // "video-quality"
                    ]
                  }
                }}
                // getIFrameRef={handleJaaSIFrameRef}

                getIFrameRef={(iframeRef) => {
                  // iframeRef.style.marginTop = "10px";
                  // iframeRef.style.border = "10px dashed #df486f";
                  // iframeRef.style.padding = "5px";
                  iframeRef.style.height = "100%";
                  iframeRef.style.width = "100%";
                  // iframeRef.style.paddingTop = "56.25%";
                  iframeRef.style.position = "relative";
                  iframeRef.style.overflow = "hidden";
                  iframeRef.style.border = 0;
                }}
              />
            ) : (
              <div className="call-form p-4">
                <h3>Tony The Developer Meet</h3>
                <p>Create / Join a Room</p>
                <form>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Meeting Room name"
                    required
                    value={roomName}
                    onChange={(e) => setRoomName(e.target.value)}
                  />

                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Your name"
                    required
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                  />

                  <button
                    className="btn btn-secondary"
                    onClick={handleClick}
                    type="submit"
                  >
                    CONNECT
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default App;
//
